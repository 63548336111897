.ProfileImage {
  object-fit: cover;
  height: 120px;
  width: 120px;
  margin: 4px;
}

/* Display bigger user avatar on smaller screens */
@media screen and (max-width: 991px) {
  .ProfileImage {
    width: 220px;
    height: 220px;
  }
}
