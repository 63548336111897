/* Styling of the search bar for user input */
.SearchBar input {
  border-radius: 15px;
  color: var(--dark-slate-grey);
  padding-left: 40px;
  margin-bottom: 10px;
}

.SearchBar input:focus {
  box-shadow: none;
  border: 1px solid var(--salmon);
}

.SearchBar input:focus::placeholder {
  color: var(--salmon);
}

.SearchIcon {
  position: absolute;
  font-size: 1.2rem;
  padding: 9px;
  padding-left: 10px;
}

.SearchIcon:hover {
  color: var(--salmon);
}

.Clear {
  position: absolute;
  font-size: 1.2rem;
  top: 10px;
  right: 12px;
}

/* Styling of badges with cuisine type */
.Badge {
  font-size: 1rem;
  margin: 0 0 10px 5px;
}

.Badge:hover {
  cursor: pointer;
}

/* Reduce the font size of badges on smaller screens */
@media all and (max-width: 991px) {
  .Badge {
    font-size: 0.9rem;
  }
}
