.Post {
  background-color: white;
  border-radius: 3px;
  margin-bottom: 16px;
  text-align: center;
}

.Container {
  display: grid;
  grid-template-columns: 60px auto 60px;
  grid-template-rows: 30px 30px;
  grid-auto-flow: row;
}

/* Display of user avatar, username & post date */
.AvatarGrid {
  grid-column: 1;
  grid-row: 1 / 3;
}

.Username {
  grid-column: 2;
  grid-row: 1/2;
  text-align: left;
  margin-left: 10px;
}

.UpdatedOn {
  grid-column: 2;
  grid-row: 2/3;
  text-align: left;
  margin-left: 10px;
  font-size: 80%;
  width: max-content;
}

.EditIcon {
  grid-column: 3;
  grid-row: 1/3;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* Type of cuisine badge within the post */
.BadgePost {
  padding: 5px 8px;
  margin: 0 4px;
  font-size: inherit;
}
