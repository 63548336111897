/* Small screens - adjust display of the top users container follow buttons, user icon & name */
@media all and (max-width: 768px) {
  .ProfilesDisplaySmall {
    flex-direction: column;
    margin: 0 !important;
  }
  .ProfilesDisplaySmall img {
    margin: 0 !important;
  }
}
