/* The user feedback message box */
.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-weight: bold;
}

.info {
  background-color: #cff4fc;
  border-color: #b6effb;
}

/* The close X button */
.closebtn {
  margin-left: 15px;
  color: var(--dark-slate-grey);
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

/* When moving the mouse over the close button */
.closebtn:hover {
  color: var(--salmon);
}

.hide {
  display: none;
}
