.LandingImage {
  width: 370px;
  margin: 0 auto;
  height: 100%;
}

.LandingButtonMargin {
  margin-right: 15px;
}

.footer {
  display: flow-root;
  margin-top: 20px;
}

.footer p {
  margin-bottom: 0;
  padding-top: 7px;
}

/* Remove right margin to the buttons on smaller screens */
@media all and (max-width: 500px) {
  .LandingButtonMargin {
    margin-right: 0;
  }
}

/* Adjust landing page image size on smaller screens */
@media all and (max-width: 440px) {
  .LandingImage {
    width: 80%;
    height: 80%;
  }
}
