/* Display of links within the small side menus  */
.Link {
  display: inline;
  margin-left: 7px;
}

.LikeFeedAddDisplay a {
  display: block !important;
  margin: 5px 0;
}

.SplitColumns {
  display: table-cell;
  border-collapse: separate;
  border-spacing: 7px;
}

/* Adjust display of icons in the menu */
@media all and (min-width: 991px) {
  .LikeFeedAddDisplay {
    display: flex;
    flex-direction: column;
  }
}

@media all and (max-width: 991px) {
  /* Display of 3 columns with menus on medium to small screens */
  .CollapsedColumn {
    display: table-cell;
    width: 31%;
  }
  .SplitColumns {
    display: inline-table;
  }
  .TwoColumns {
    border-spacing: 0 !important;
  }
}

@media all and (max-width: 768px) {
  /* Display of menus one under another on smaller screens */
  .CollapsedColumn {
    display: inline-flex;
    width: 100%;
    justify-content: space-around;
  }

  .CollapsedColumn:nth-child(2) {
    margin: 0;
  }

  /* Adjust display of top users' icons and follow buttons */
  .UsersDisplay {
    display: flex;
    justify-content: space-around;
  }

  /* Display of posts categories badges */
  .CategoriesColumn {
    display: inline-table;
  }
}

@media all and (max-width: 430px) {
  /* Adjust display of icons and links below them */
  .Link {
    display: block;
  }

  .LikeFeedAddDisplay a {
    text-align: center !important;
    margin: 0;
  }

  .LikeFeedAddDisplay img {
    vertical-align: 0;
  }
}
