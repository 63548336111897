.Container {
  min-height: 300px;
}

.ButtonChangeImage {
  color: var(--dark-slate-grey);
  padding: 7px;
}

.UploadPicture:hover {
  cursor: pointer;
}