.Asset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 120px;
}

.Asset > img {
  width: 150px;
  height: 140px;
}

.Asset .spinner {
  width: 70px;
  height: 65px;
}
